// Import Phaser package
// https://codesandbox.io/s/pkk8r05yx7
import Phaser from "phaser";
import { GlobalSettings } from "./GlobalSettings";
import { Player } from "./Player";
import { Enemy } from "./Enemy";
import { EnemyShooter } from "./EnemyShooter";
import { EnemyBoss } from "./EnemyBoss";

class StageOne extends Phaser.Scene {
  constructor(...args) {
    super(...args);
    // Create a promise that will be used to signal scene
    // completion and game over
    this.done = new Promise((resolve, reject) => {
      this._resolveDone = resolve;
      this._rejectDone = reject;
    });
  }

  init() {
    // Handles the enemies spawning
    this.enemies = this.add.group();
    this.enemyBullets = this.add.group();
    this.enemyDelta = 0; // ms
    this.enemyShooterDelta = 0; // ms
    this.playerBullets = this.add.group();
    this.powerUps = this.add.group({ maxSize: 3 });
    this.bossDefeated = false;
    this.boss = null;
  }
  preload() {
    this.load.crossOrigin = "anonymous";
    this.load.audio('playerFire', 'https://jmp.sh/s/Xv1e3uZhpjOD62emCIeO'); // Load the fire sound

    try{
      this.load.image("bullet", `https://i.ibb.co/yFHgc0H/bullet.png`);
    }catch(e){
      console.log(e)
    }
    this.load.image("powerup1", `https://i.ibb.co/f84kzkP/pow.png`);
    this.load.image("sea", `https://i.ibb.co/Vptx3Cy/Capture-1.png`);
    this.load.image("trumpinator", "https://i.ibb.co/c24Fcqp/trumpinator4.png")
    this.load.spritesheet(
      "player1",
      `https://i.ibb.co/30wxR53/player01.png`,
      { 
        frameWidth: 100,
        frameHeight: 100
      }
    );

    this.load.spritesheet("enemy", `https://i.ibb.co/jhz5JJy/biden.png`, {
      frameHeight: 65,
      frameWidth: 65
    });
    
    this.load.spritesheet(
      "shooting-enemy",
      `https://i.ibb.co/RBG3Xb3/enemy.png`,
      {
        frameHeight: 65,
        frameWidth: 65
      }
    );

    this.load.spritesheet("boss", `https://i.ibb.co/7XBwByY/boss-1.png`, {
      frameHeight: 85,
      frameWidth: 103
    });

    this.load.spritesheet(
      "explosion", 
      `${GlobalSettings.assetsUrl}/explosion.png`,
      {
        frameHeight: 32,
        frameWidth: 32
      }
    );
  }

  create() {
    // Set up physics world
    this.physics.world.setBounds(
      0,
      0,
      GlobalSettings.width,
      GlobalSettings.height
    );

    // Add Background
    this.bg = this.add.tileSprite(
      GlobalSettings.width / 2,
      GlobalSettings.height / 2,
      GlobalSettings.width,
      GlobalSettings.height,
      "sea"
    );
    // Add Player
    this.player = new Player(this);

    // Enable keyboard input for this scene
    this.keys = this.input.keyboard.createCursorKeys();
    this.keys.W = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
    this.keys.A = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.A);
    this.keys.S = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.S);
    this.keys.D = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);

    this.createAnimation();

    // Collision between player and enemies
    this.physics.add.overlap(this.player, this.enemies, (player, enemy) => {
      // handle Player And Enemies Collision
      this.handlePlayerAndEnemiesCollision(player, enemy);
    });
    // Collision between enemies and player bullets
    this.physics.add.overlap(
      this.enemies,
      this.player.bullets,
      (enemy, bullet) => {
        this.handleEnemyAndPlayerBulletCollision(enemy, bullet);
      }
    );

    // Collision between enemies and player bullets
    this.physics.add.overlap(
      this.player,
      this.enemyBullets,
      (player, bullet) => {
        this.handlePlayerAndEnemyBulletsCollision(player, bullet);
      }
    );

    this.physics.add.overlap(this.player, this.powerUps, (player, powerUp) => {
      this.handlePlayerAndPowerUpCollision(player, powerUp);
    });

    this.textLives = this.add.text(10, 10, `Lives: ${this.player.lives}`);
    this.textScore = this.add.text(100, 10, `Score: ${this.player.score}`);
  }

  // Is called 60 times per second
  update(elapsedTime, deltaTime) {
    // Update timekeepers
    this.enemyDelta += deltaTime;
    this.enemyShooterDelta += deltaTime;

    // Calls the player update method
    if (this.player && this.player.active) {
      this.player.update(elapsedTime, deltaTime);
    }

    if (elapsedTime > 3 && this.enemyDelta > GlobalSettings.enemySpawnDelay) {
      this.enemyDelta = 0;
      this.spawnEnemy();
    }

    if (
      elapsedTime > 5 &&
      this.enemyShooterDelta > GlobalSettings.enemyShooterSpawnDelay
    ) {
      this.enemyShooterDelta = 0;
      this.spawnEnemyShooter();
    }

    // Update all existing enemies
    this.enemies.getChildren().forEach(enemy => {
      enemy.update(elapsedTime, deltaTime);
    });

    // Add motion to bg tiles
    this.bg.tilePositionY -= 0.2;
    this.checkForEndGame();
    this.cleanupArtifacts();
  }

  spawnEnemy() {
    const enemy = new Enemy(
      // Pass scene to the enemy we're creating
      this,
      // Set a random X position for new enemy
      this.game.rdg.between(32, this.game.config.width - 32),
      // Set fixed Y position for the new enemy
      32
    );
    this.enemies.add(enemy);
  }

  spawnEnemyShooter() {
    const enemy = new EnemyShooter(
      // Pass scene to the enemy we're creating
      this
    );
    this.enemies.add(enemy);
  }

  spawnBoss() {
    this.boss = new EnemyBoss(this);
    this.enemies.add(this.boss);
  }

  spawnPowerUp(x = 0, y = 0) {
    if (this.powerUps.getChildren().length < this.powerUps.maxSize) {
      const powerUp = this.physics.add.image(x, y, "powerup1");
      this.powerUps.add(powerUp);
      powerUp.setVelocity(0, 50);
    }
  }

  handlePlayerAndEnemiesCollision(player, enemy) {
    enemy.handleCollision();
    if (!enemy.active) {
      this.player.addToScore(enemy.reward);
    }
    player.handleCollision();
  }

  handleEnemyAndPlayerBulletCollision(enemy, bullet) {
    // Destroy the bullet
    bullet.destroy();
    // Destroy the enemy
    enemy.handleCollision();
    if (!enemy.active) {
      this.player.addToScore(enemy.reward);
      const powerUpRoll = this.game.rdg.frac();
      if (powerUpRoll < enemy.dropRate && this.player.powerLevel < 5) {
        this.spawnPowerUp(enemy.x, enemy.y);
      }
    }
  }

  handlePlayerAndEnemyBulletsCollision(player, bullet) {
    bullet.destroy();
    player.handleCollision();
  }

  handlePlayerAndPowerUpCollision(player, powerUp) {
    player.addPowerUp();
    powerUp.destroy();
  }

  updateGUI() {
    this.textLives.text = `Lives: ${this.player.lives}`;
    this.textScore.text = `Score: ${this.player.score}`;
  }

  checkForEndGame() {
    if (this.player.score > 10000 && !(this.boss instanceof EnemyBoss)) {
      this.spawnBoss();
    } else if (this.boss instanceof EnemyBoss && this.boss.health < 1) {
      this._resolveDone();
    } else if (!this.player.active) {
      this._rejectDone();
    }
  }

  cleanupArtifacts() {
    this.enemyBullets.getChildren().forEach(bullet => {
      if (
        !Phaser.Geom.Rectangle.Overlaps(
          this.physics.world.bounds,
          bullet.getBounds()
        )
      ) {
        bullet.destroy();
      }
    });
    this.playerBullets.getChildren().forEach(bullet => {
      if (
        !Phaser.Geom.Rectangle.Overlaps(
          this.physics.world.bounds,
          bullet.getBounds()
        )
      ) {
        bullet.destroy();
      }
    });

    this.powerUps.getChildren().forEach(powerUp => {
      if (
        !Phaser.Geom.Rectangle.Overlaps(
          this.physics.world.bounds,
          powerUp.getBounds()
        )
      ) {
        powerUp.destroy();
      }
    });
  }

  createAnimation() {
    // Regular fly animation
    this.anims.create({
      key: "enemy_fly",
      frames: [
        { key: "enemy", frame: 0 },
        { key: "enemy", frame: 1 },
        { key: "enemy", frame: 2 }
      ],
      frameRate: 30,
      repeat: -1
    });

    this.anims.create({
      key: "enemyShooter_fly",
      frames: [
        { key: "shooting-enemy", frame: 0 },
        { key: "shooting-enemy", frame: 1 },
        { key: "shooting-enemy", frame: 2 }
      ],
      frameRate: 30,
      repeat: -1
    });

    this.anims.create({
      key: "enemyBoss_fly",
      frames: [
        { key: "boss", frame: 0 },
        { key: "boss", frame: 1 },
        { key: "boss", frame: 2 }
      ],
      frameRate: 30,
      repeat: -1
    });

    this.anims.create({
      key: "enemy_ghost",
      frames: [
        { key: "enemy", frame: 3 },
        { key: "enemy", frame: 0 },
        { key: "enemy", frame: 3 },
        { key: "enemy", frame: 1 }
      ],
      frameRate: 20,
      repeat: 1
    });

    this.anims.create({
      key: "enemyShooter_ghost",
      frames: [
        { key: "shooting-enemy", frame: 3 },
        { key: "shooting-enemy", frame: 0 },
        { key: "shooting-enemy", frame: 3 },
        { key: "shooting-enemy", frame: 1 }
      ],
      frameRate: 20,
      repeat: 1
    });

    this.anims.create({
      key: "enemyBoss_ghost",
      frames: [
        { key: "boss", frame: 3 },
        { key: "boss", frame: 0 },
        { key: "boss", frame: 3 },
        { key: "boss", frame: 1 }
      ],
      frameRate: 20,
      repeat: -1
    });

    // Add explosion animation
    this.anims.create({
      key: "explode",
      frames: [
        { key: "explosion", frame: 0 },
        { key: "explosion", frame: 1 },
        { key: "explosion", frame: 2 },
        { key: "explosion", frame: 3 },
        { key: "explosion", frame: 4 },
        { key: "explosion", frame: 5 }
      ],
      frameRate: 15,
      repeat: 0,
      hideOnComplete: true
    });
  }
}

export { StageOne };
