import { Enemy } from "./Enemy";
import { GlobalSettings } from "./GlobalSettings";

export class EnemyShooter extends Enemy {
  constructor(scene, x = 0, y = 0) {
    super(scene, x, y, "shooting-enemy");

    const vX = this.scene.game.rdg.between(-25, 25);
    const vY = this.scene.game.rdg.between(20, 25);
    const rad = Math.atan2(-vX, vY);
    this.setVelocity(vX, vY);
    this.setRotation(rad);
    this.anims.play("enemyShooter_fly");

    this.bulletDelta = 0;
    this.bullets = this.scene.enemyBullets;
    this.dropRate = GlobalSettings.enemyShooterDropRate;
    this.health = GlobalSettings.enemyShooterHealth;
    this.reward = GlobalSettings.enemyShooterReward;
  }

  update(_, deltaTime) {
    this.bulletDelta += deltaTime;

    if (this.bulletDelta > GlobalSettings.enemyShooterBulletDelay) {
      this.bulletDelta = 0;
      this.fireBullet();
    }
  }

  fireBullet() {
    // create the bullet
    const bullet = this.scene.physics.add.image(this.x, this.y, "bullet");
    // Make it so the bullet moves towards the player
    this.scene.physics.moveTo(
      bullet,
      this.scene.player.x,
      this.scene.player.y,
      50
    );
    // Add the bullet to our bullet group so we can do collision checking with it.
    this.bullets.add(bullet);
  }
}
