import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUserShield, faLock, faUser, faBullhorn, faShieldAlt, faSnowflake, faRocket } from '@fortawesome/free-solid-svg-icons';

// Register the chart elements
Chart.register(ArcElement, Tooltip, Legend);

const Tokenomics = () => {
  const data = {
    labels: ['Pump.fun (97.5%)', 'Team (2.5%)', 'Marketing and Community (1%)'],
    datasets: [
      {
        label: 'Tokenomics Distribution',
        data: [97.5, 1.5, 1],
        backgroundColor: [
          'rgba(54, 162, 235, 0.6)', // Pump.fun
          'rgba(255, 99, 132, 0.6)', // Team
          'rgba(255, 206, 86, 0.6)', // Marketing and Community
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right',
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div className="tokenomics-section" style={{ display: 'flex', marginTop: '50px', justifyContent: 'space-between' }}>
      {/* Text Section */}
      <div style={{ flex: '1', paddingRight: '20px', maxWidth: '50%' }}> 
        <h3 className="title" style={{color: "#ebeced"}}>Tokenomics</h3>
        <div className="from-left" style={{ marginTop: '20px' }}>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Ownership Renounced:</strong> <FontAwesomeIcon icon={faUserShield} /> Ownership has been renounced to ensure full decentralization.</p>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Not Mintable:</strong> <FontAwesomeIcon icon={faLock} /> The total supply is fixed, and no further tokens can be minted.</p>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Team Allocation:</strong> <FontAwesomeIcon icon={faUser} /> 2.5% of the total supply is allocated to the team to support long-term development.</p>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Marketing & Community:</strong> <FontAwesomeIcon icon={faBullhorn} /> 1% is allocated for marketing and community-building efforts.</p>
           <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Not Mutable:</strong> <FontAwesomeIcon icon={faShieldAlt} /> The token's contract parameters cannot be altered after deployment.</p>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Not Freezable:</strong> <FontAwesomeIcon icon={faSnowflake} /> The token cannot be frozen, guaranteeing liquidity for holders.</p>
          <p style={{color: "white"}}><FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green' }} /> <strong>Fair Launch on Pump.fun:</strong> <FontAwesomeIcon icon={faRocket} /> This token will be launched fairly on <a href="https://pump.fun" target="_blank" rel="noreferrer">Pump.fun</a>, with no presale, giving everyone equal opportunity to participate.</p>
        </div>
      </div>

      {/* Tokenomics Pie Chart */}
      <div style={{ flex: '1', maxWidth: '50%' }}>
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default Tokenomics;
