import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation to Home
import { ShooterGame } from "./ShooterGame/ShooterGame";

const Game = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const gameContainer = document.getElementById('game');
    gameContainer.innerHTML = ''; // Clear game container

    // Initialize the game logic (ShooterGame)
    const game = new ShooterGame(); // Assuming ShooterGame is imported properly

    // Resize the game when window resizes
    const resizeGame = () => {
      game.resizeGame(window.innerWidth, window.innerHeight);
    };

    // Call resize function on mount
    resizeGame();

    // Add event listener to resize the game on window resize
    window.addEventListener('resize', resizeGame);

    // Cleanup on component unmount
    return () => {
      window.removeEventListener('resize', resizeGame);
      // Destroy the game when component unmounts
      if (game) game.destroy(true);
    };
  }, []);

  // Restart the game (reload the page)
  const handleRestart = () => {
    window.location.reload();
  };

  // Navigate to Home
  const handleHome = () => {
    navigate('/');
  };

  return (
    <>
      <div
        id="game"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw', // Full viewport width
          height: '100vh', // Full viewport height
          margin: '0',
          padding: '0',
          overflow: 'hidden', // Ensure no scrollbars appear
          zIndex: 1 // Ensure game stays below any header or navbar if present
        }}
      ></div>

      {/* Buttons at the bottom-left corner */}
      <div
        style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          left: '20px', // Adjust as needed
          zIndex: 10, // Ensure buttons are above the game
          display: 'flex',
          flexDirection: 'column',
          gap: '10px' // Space between the buttons
        }}
      >
        <button className="nes-btn is-primary" onClick={handleRestart}>
          Restart
        </button>
        <button className="nes-btn is-error" onClick={handleHome}>
          Home
        </button>
      </div>
    </>
  );
};

export default Game;
