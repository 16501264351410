import Phaser from "phaser";
import { Enemy } from "./Enemy";
import { GlobalSettings } from "./GlobalSettings";

export class EnemyBoss extends Enemy {
  constructor(scene) {
    super(scene, GlobalSettings.width / 2, 0, "boss");

    this.bulletDelta = 0;
    this.bullets = this.scene.enemyBullets;
    this.isInvicible = true;
    this.health = GlobalSettings.enemyBossHealth;
    this.reward = GlobalSettings.enemyBossReward;
    this.dropRate = GlobalSettings.enemyBossDropRate;

    this.setScale(0.75);
    this.anims.play("enemyBoss_ghost");
    this.scene.tweens.add({
      targets: this,
      scaleX: 1,
      scaleY: 1,
      duration: 2000,
      onComplete: () => {
        this.setVelocityX(100);
        this.isInvicible = false;
        this.anims.play("enemyBoss_fly");
      }
    });

    // Set boss moving pattern
    this.body.bounce.x = 1;
    this.body.collideWorldBounds = true;
  }

  update(_, deltaTime) {
    this.bulletDelta += deltaTime;

    if (this.bulletDelta > GlobalSettings.enemyBossBulletDelay) {
      this.bulletDelta = 0;
      this.fireBullet();
    }
  }

  fireBullet() {
    if (this.health > GlobalSettings.enemyBossHealth * 0.5) {
      let bullet = this.scene.physics.add.image(this.x, this.y + 32, "bullet");
      this.scene.physics.moveTo(
        bullet,
        this.scene.player.x,
        this.scene.player.y,
        100
      );
      this.bullets.add(bullet);
      Array.from({ length: 3 }).forEach((_, i) => {
        // Left hand side bullet
        const bulletLeft = this.scene.physics.add.image(
          this.x - 20 * (i + 1),
          this.y + 32,
          "bullet"
        );
        this.scene.physics.moveTo(
          bulletLeft,
          this.scene.player.x,
          this.scene.player.y,
          100
        );
        this.bullets.add(bulletLeft);

        // Right hand side bullet
        const bulletRight = this.scene.physics.add.image(
          this.x + 20 * (i + 1),
          this.y + 32,
          "bullet"
        );
        this.scene.physics.moveTo(
          bulletRight,
          this.scene.player.x,
          this.scene.player.y,
          100
        );
        this.bullets.add(bulletRight);
      });
    } else {
      const bullet = this.scene.physics.add.image(
        this.x,
        this.y + 32,
        "bullet"
      );
      bullet.setVelocity(0, 200);
      this.bullets.add(bullet);
      Array.from({ length: 3 }).forEach((_, i) => {
        const bulletLeft = this.scene.physics.add.image(
          this.x,
          this.y + 32,
          "bullet"
        );
        const theta = -270 - (i + 1) * 10;
        this.scene.physics.velocityFromAngle(
          theta,
          200,
          bulletLeft.body.velocity
        );
        this.bullets.add(bulletLeft);

        const bulletRight = this.scene.physics.add.image(
          this.x,
          this.y + 32,
          "bullet"
        );
        const ro = -270 + (i + 1) * 10;
        this.scene.physics.velocityFromAngle(
          ro,
          200,
          bulletRight.body.velocity
        );
        this.bullets.add(bulletRight);
      });
    }
  }

  handleCollision() {
    if (this.isInvicible) {
      return;
    }

    this.health -= 1;
    if (this.health < 1) {
      // Animate enemy explosion
      const enemyExplosion = this.scene.add.sprite(
        this.x,
        this.y,
        "explosion",
        0
      );
      this.destroy();
      enemyExplosion.on(
        `${Phaser.Animations.Events.SPRITE_ANIMATION_KEY_COMPLETE}explode`,
        () => {
          enemyExplosion.destroy();
        }
      );
      enemyExplosion.anims.play("explode");
    }
  }
}
