import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './components/NavBar';
import Game from './components/Game';
import Home from './components/Home';

const App = () => {
  const location = useLocation();

  return (
    <>
      {/* Only show NavBar if the route is not '/game' */}
      {location.pathname !== '/game' && <NavBar />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/game" element={<Game />} />
        {/* Add more routes for other sections */}
      </Routes>
    </>
  );
};

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
