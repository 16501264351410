// Create the global settings for our game
export const GlobalSettings = {
  // Enemy related settings
  enemyDropRate: 0.3,
  enemyHealth: 1, // HP
  enemyReward: 100, // points
  enemySpawnDelay: 1000, // ms
  // Enemy Shooter related settings
  enemyShooterBulletDelay: 2000, // ms
  enemyShooterHealth: 2,
  enemyShooterDropRate: 0.5,
  enemyShooterReward: 500,
  enemyShooterSpawnDelay: 3000, // ms
  // Enemy Boss related settings
  enemyBossBulletDelay: 2000, // ms
  enemyBossDropRate: 0,
  enemyBossHealth: 500,
  enemyBossReward: 5000,
  // Player related settings
  playerShotdelay: 100, // ms
  playerInitialLives: 3, // lives
  // Game level settings
  assetsUrl: "https://leomeloxp.github.io/shmup/assets",
  height: window.innerHeight, // Full screen height
  width: window.innerWidth  // Full screen width
};
