import React from 'react';
import videoSource from '../assets/video.mp4'; // Import your video file

const VideoSection = () => {
    return (
        <div style={{ 
            display: 'flex',
            justifyContent: 'center',  // Horizontally center the video
            alignItems: 'center',   
        }}>
            <video
                width="60%"        // Set the video to be 80% of the container's width
                maxWidth="600px"    // Limit the maximum width to 600px
                height="auto"
                controls

            >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoSection;
