import Phaser from "phaser";
import { GlobalSettings } from "./GlobalSettings";

export class Enemy extends Phaser.Physics.Arcade.Sprite {
  constructor(scene, x = 0, y = 0, sprite = "enemy") {
    super(scene, x, y, sprite, 0);

    scene.add.existing(this);
    scene.physics.add.existing(this);

    this.setVelocity(0, 35);
    this.dropRate = GlobalSettings.enemyDropRate;
    this.health = GlobalSettings.enemyHealth;
    this.reward = GlobalSettings.enemyReward;
    this.anims.play("enemy_fly");
  }

  update() {
    if (
      !Phaser.Geom.Rectangle.Overlaps(
        this.scene.physics.world.bounds,
        this.getBounds()
      )
    ) {
      this.destroy();
    }
  }

  handleCollision() {
    this.health -= 1;
    if (this.health < 1) {
      // Animate enemy explosion
      const enemyExplosion = this.scene.add.sprite(
        this.x,
        this.y,
        "explosion",
        0
      );
      this.destroy();
      enemyExplosion.on(
        `${Phaser.Animations.Events.SPRITE_ANIMATION_KEY_COMPLETE}explode`,
        () => {
          enemyExplosion.destroy();
        }
      );
      enemyExplosion.anims.play("explode");
    }
  }
}
