import React, { useEffect ,useState, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import Tokenomics from './Tokenomics'; 
import Roadmap from './Roadmap';  
import VideoSection from './VideoSection'; // Import VideoSection component
import { useNavigate } from 'react-router-dom';
 
const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isTrumpinatorPage = location.pathname === '/trumpinator';

  const playNowSection = useRef(null);
  const storySection = useRef(null);
  const tokenomicsSection = useRef(null);
  const roadmapSection = useRef(null);
  const partnersSection = useRef(null);

  // Function to scroll to the specific section
  const scrollToSection = (sectionId) => {
    const sections = {
      'play-now': playNowSection,
      'story': storySection,
      'tokenomics': tokenomicsSection,
      'roadmap': roadmapSection,
      'partners': partnersSection,
    };
    
    const sectionRef = sections[sectionId];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleClick = (sectionId) => {
    scrollToSection(sectionId);
  };

  const [isCopied, setIsCopied] = useState(false);
  const tokenAddress = "Launching on Pump.fun on November 1"; 
  const copyToClipboard = () => {
    navigator.clipboard.writeText(tokenAddress)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };
 
  return (
    <>
    
      {/* Pass the scrollToSection function only when not on the Trumpinator page */}
      <NavBar scrollToSection={!isTrumpinatorPage ? scrollToSection : null} isTrumpinatorPage={isTrumpinatorPage} />

      {/* Tokenomics Section */}
      <div className="container" ref={tokenomicsSection} style={{ backgroundColor: 'transparent', color: 'gray',   }}>
        <div className="row">


        <div className="col-md-12 col-sm-12 col-xs-12">
        <div id="ca" ref={storySection} className="nes-container" style={{ marginTop: '50px', backgroundColor: 'transparent' }}  onClick={copyToClipboard}>
          <h3 style={{ color: "white" }}>  <i style={{width: 30}} className={isCopied ? "fa fa-check" : "fa fa-copy"}></i> CA: {tokenAddress}  🚀</h3> 
        </div>
          </div>


          <div className="col-md-4 col-sm-12 col-xs-12">
            <div className="nes-container with-title" style={{ marginTop: '50px', marginBottom: '50px', backgroundColor: 'transparent',  color: 'gray', height: 500 }}>
              <div className="title" style={{ color: 'gray' }}> </div>
              <div>
                <ul className="nes-list is-circle" style={{ marginBottom: 0 }}>
                  <li style={{marginTop: 30}}>
                    <button
                      onClick={() => navigate('/game')}
                      type="button"
                      style={{ width: '200px' }}
                      className="nes-btn is-success"
                    >
                      <i className="fas fa-play"></i> PLAY NOW
                    </button>
                  </li>
                  <li style={{marginTop: 10}}>
                    <button onClick={() => window.open('#', '_blank')} style={{ width: '200px' }} type="button" className="nes-btn is-error">
                      <img src="https://www.svgrepo.com/show/507090/gen-pill.svg" alt="Pump Fun Icon" style={{ width: '20px', height: '20px', verticalAlign: 'middle' }} /> PUMP.FUN
                    </button>
                  </li>
                  <li style={{marginTop: 10}}>
                    <button onClick={() => window.open('https://x.com/trumpt_sol', '_blank')} style={{ width: '200px' }} type="button" className="nes-btn is-primary">
                      <i className="fab fa-twitter"></i> TWITTER
                    </button>
                  </li>
                  <li style={{marginTop: 10}}>
                    <button onClick={() => window.open('#', '_blank')} style={{ width: '200px' }} type="button" className="nes-btn is-primary">
                      <i className="fab fa-telegram-plane"></i> TELEGRAM
                    </button>
                  </li>
                  <li style={{marginTop: 10}}><button onClick={() => handleClick('tokenomics')}  style={{ width: '200px' }} type="button" className="nes-btn">TOKENOMICS</button></li>
                  <li style={{marginTop: 10}}><button onClick={() => handleClick('roadmap')} style={{ width: '200px' }} type="button" className="nes-btn">ROADMAP</button></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-sm-12 col-xs-12">
            <div  id="story" ref={storySection} className="nes-container" style={{ marginTop: '50px', backgroundColor: 'transparent' }}>
              <VideoSection />
            </div> 
          </div>

          
         
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div id="tokenomics" ref={tokenomicsSection} className="nes-container" style={{ marginTop: '100px', backgroundColor: 'transparent'  }}>
                <Tokenomics />  
              </div>
            </div>
            <div id="roadmap" ref={roadmapSection} className="nes-container"  style={{marginTop: '100px',  backgroundColor: 'transparent', width: "100%" }}>
              <Roadmap />  
            </div>

            
            {/* Partners Section */}
            <div id="partners" ref={partnersSection} className="nes-container" style={{ marginTop: '50px', backgroundColor: 'transparent', marginBottom: "100px",width: "100%"  }}>
              <h3 className="title" style={{ color: 'gray' }}> </h3>
              <div className="partners-list">
                <a href="#" target="_blank" rel="noreferrer">
                  <img src="icons/telegram.png" alt="Telegram" className="partner-icon" />
                </a>
                <a href="https://x.com/trumpt_sol" target="_blank" rel="noreferrer">
                  <img src="icons/twitter.png" alt="Twitter" className="partner-icon" />
                </a>
                <a href="#" target="_blank" rel="noreferrer">
                  <img src="icons/pumpfun.png" alt="Pump.fun" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/solscan.png" alt="Solscan" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/dexscreener.png" alt="Dexscreener" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/coingecko.png" alt="Coingecko" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/coinmarketcap.png" alt="Coinmarketcap" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/dextools.png" alt="Dextools" className="partner-icon" />
                </a>
                <a href="#" >
                  <img src="icons/raydium.png" alt="Raydium" className="partner-icon" />
                </a>
              </div>
            </div>
        </div> 
      </div>
    </>
  );
};

export default Home;
