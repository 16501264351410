import Phaser from "phaser";
import { StageOne } from "./StageOne";
import { GlobalSettings } from "./GlobalSettings";

class ShooterGame extends Phaser.Game {
  constructor() {
    super({
      type: Phaser.AUTO,
      width: GlobalSettings.width,
      height: GlobalSettings.height,
      parent: "game",
      physics: {
        default: "arcade",
        arcade: {
          debug: false
        }
      }
    });

    this.rdg = new Phaser.Math.RandomDataGenerator();
    this.stageOne = new StageOne();
    this.scene.add("StageOne", this.stageOne);
    this.scene.start("StageOne");

    this.stageOne.done
      .then(() => {
        this.showEndGameMessage("YOU WIN!");
      })
      .catch(() => {
        this.showEndGameMessage("Game Over!");
      });

    this.throttleResize = this.throttle(this.resizeGame.bind(this), 200);
  }

  showEndGameMessage(message = "Game Over!") {
    const popupBackground = this.stageOne.add.graphics();
    popupBackground.fillStyle(0x000000, 0.8);
    popupBackground.fillRect(GlobalSettings.width / 2 - 200, GlobalSettings.height / 2 - 100, 400, 200);

    const image = this.stageOne.add.image(GlobalSettings.width / 2 - 100, GlobalSettings.height / 2, 'trumpinator');
    image.setOrigin(0.5);
    
    const mainMessage = this.stageOne.add.text(
      GlobalSettings.width / 2 + 30,
      GlobalSettings.height / 2 - 30,
      message,
      {
        align: "center",
        fontSize: 32,
        color: '#FFFFFF'
      }
    ).setOrigin(0.5);

    const finalScore = this.stageOne.add.text(
      GlobalSettings.width / 2 + 30,
      GlobalSettings.height / 2 + 10,
      `Your final score was ${this.stageOne.player.score}.`,
      {
        align: "center",
        fontSize: 16,
        color: '#FFFFFF'
      }
    ).setOrigin(0.5);

    // Create a container for the buttons
    const buttonContainer = this.stageOne.add.container(GlobalSettings.width / 2, GlobalSettings.height / 2 + 50);
    
    // // Home Button
    // const homeButton = this.stageOne.add.text(-60, 0, 'Home', {
    //   fontSize: 24,
    //   backgroundColor: '#FF0000',
    //   color: '#FFFFFF',
    //   padding: { x: 10, y: 5 },
    //   fixedWidth: 100,
    //   align: 'center'
    // }).setOrigin(0.5)
    //   .setInteractive()
    //   .on('pointerdown', () => {
    //     window.location.href = '/'; // Redirect to home
    //   });

    // // Play Again Button
    // const playAgainButton = this.stageOne.add.text(60, 0, 'Play Again', {
    //   fontSize: 24,
    //   backgroundColor: '#00FF00',
    //   color: '#FFFFFF',
    //   padding: { x: 10, y: 5 },
    //   fixedWidth: 100,
    //   align: 'center'
    // }).setOrigin(0.5)
    //   .setInteractive()
    //   .on('pointerdown', () => {
    //     window.location.reload(); // Reload the page
    //   });

    // // Add buttons to the container
    // buttonContainer.add(homeButton);
    // buttonContainer.add(playAgainButton);
}


  resizeGame() {
    if (this.renderer && this.renderer.resize && this.scale) {
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.scale.resize(width, height);
    }
  }

  throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  }
}

export { ShooterGame };
