import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faGamepad, faUsers, faStore, faCube, faUserFriends, faRandom, faVoteYea, faBullhorn } from '@fortawesome/free-solid-svg-icons';
import roadmapImage from '../assets/trumpinator3.png'; // Replace with your actual image path

const Roadmap = () => {
  return (
    <div  style={{ marginTop: '50px', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginLeft: "5%", marginRight: "5%"}}>
      
 
      <div style={{ flex: '1', marginRight: '40px' }}>
        <h3 className="title" style={{color: "#ebeced"}}>🚀 Project Roadmap</h3>

        {/* Phase 1: Meme Coin Launch */}
        <div style={{ marginBottom: '30px' }}>
          <h4 style={{color: "#ebeced"}}><FontAwesomeIcon icon={faRocket} style={{ color: 'green' }} /> Phase 1: Meme Coin Launch</h4>
          <ul style={{ paddingLeft: '20px' }}>
            <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faBullhorn} style={{ color: '#ebeced' }} /> Launch on Pump.fun</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faUsers} style={{ color: '#ebeced' }} /> Initial Game Release</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faUsers} style={{ color: '#ebeced' }} /> Community Building with Meme Influencers</li>
          </ul>
        </div>

        {/* Phase 2: Game Expansion & Features */}
        <div style={{ marginBottom: '30px' }}>
          <h4 style={{color: "#ebeced"}}><FontAwesomeIcon icon={faGamepad} style={{ color: 'green' }} /> Phase 2: Game Expansion & Features</h4>
          <ul style={{ paddingLeft: '20px' }}>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faGamepad} style={{ color: '#ebeced' }} /> Game Feature Enhancements</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faStore} style={{ color: '#ebeced' }} /> Marketplace Integration</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faRocket} style={{ color: '#ebeced' }} /> Expanded Meme Universe</li>
          </ul>
        </div>

        {/* Phase 3: 3D Game Version & Multiplayer Mode */}
        <div style={{ marginBottom: '30px' }}>
          <h4 style={{color: "#ebeced"}}><FontAwesomeIcon icon={faCube} style={{ color: 'green' }} /> Phase 3: 3D Game Version & Multiplayer Mode</h4>
          <ul style={{ paddingLeft: '20px' }}>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faCube} style={{ color: '#ebeced' }} /> 3D Game Version</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faUserFriends} style={{ color: '#ebeced' }} /> Multiplayer 1 vs 1 Mode</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faRandom} style={{ color: '#ebeced' }} /> Cross-Chain Expansion</li>
           <li style={{color: "#ebeced"}}><FontAwesomeIcon icon={faVoteYea} style={{ color: '#ebeced' }} /> Community Voting for Features</li>
          </ul>
        </div>
      </div>

      {/* Right Side: Image */}
      <div style={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={roadmapImage} alt="Trumpinator Roadmap" style={{ width: '100%', maxWidth: '400px', height: 'auto' }} />
      </div>

    </div>
  );
};

export default Roadmap;
