import React from 'react';
import { useNavigate } from 'react-router-dom';
import trumpinatorLogo from '../assets/trumpinator1.png'; // Replace with your actual image path

const NavBar = ({ scrollToSection, isTrumpinatorPage }) => {
  const navigate = useNavigate();

  const handleClick = (sectionId) => {
    if (scrollToSection) {
      scrollToSection(sectionId);
    } else {
      navigate('/');
      setTimeout(() => {
        if (scrollToSection) {
          scrollToSection(sectionId);
        }
      }, 100);
    }
  };

  return (
    <nav id="trumpinator-header">
      <div className="container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <h2 style={{ marginBottom: 0 }} onClick={() => handleClick('home')}>
            <img src={trumpinatorLogo} alt="Trumpinator logo" style={{ width: '60px', height: 'auto' }} />
          </h2>
        </div>

        {/* Navigation buttons */}
        <ul className="header-nav nes-list desktop-nav" style={{ display: 'flex', listStyleType: 'none', gap: '20px', marginBottom: 0 }}>
          <li><button className="nes-btn" onClick={() => handleClick('home')}>Home</button></li>
          <li><button className="nes-btn is-success" onClick={() => navigate('/game')}>Play Now</button></li> 
          <li><button className="nes-btn" onClick={() => handleClick('tokenomics')}>Tokenomics</button></li>
          <li><button className="nes-btn" onClick={() => handleClick('roadmap')}>Roadmap</button></li>
          <li><button className="nes-btn" onClick={() => handleClick('partners')}>Partners</button></li>
        </ul>

        {/* Buy Now button */}
        <div>
          <button className="nes-btn is-success" onClick={() => window.open('https://pump.fun', '_blank')}>Buy Now</button>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
