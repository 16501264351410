import Phaser from "phaser";
import { GlobalSettings } from "./GlobalSettings";

const initialX = GlobalSettings.width / 2;
const initialY = GlobalSettings.height - 50;

export class Player extends Phaser.Physics.Arcade.Sprite {
  constructor(scene) {
    super(scene, initialX, initialY, "player1", 0);

    // Add the player sprite to the scene
    scene.add.existing(this);
    // Add the player physics body to the scene
    scene.physics.add.existing(this);

    this.setCollideWorldBounds(true);

    this.bullets = this.scene.playerBullets;
    this.shotDelay = GlobalSettings.playerShotdelay;
    this.shotDeltaTime = 0;
    this.lives = GlobalSettings.playerInitialLives;
    this.score = 0;
    this.powerLevel = 1;

    this.createAnimation();
    this.anims.play("fly");
  }

  update(_, deltaTime) {
    // Reset player velocity
    this.setVelocity(0);
    // Handle moving left
    if (this.scene.keys.left.isDown || this.scene.keys.A.isDown) {
      this.body.velocity.x -= 300;
    }
    // Handle moving right
    if (this.scene.keys.right.isDown || this.scene.keys.D.isDown) {
      this.body.velocity.x += 300;
    }
    // Handle moving up
    if (this.scene.keys.up.isDown || this.scene.keys.W.isDown) {
      this.body.velocity.y -= 300;
    }
    // Handle moving down
    if (this.scene.keys.down.isDown || this.scene.keys.S.isDown) {
      this.body.velocity.y += 300;
    }
    // Increment shotDeltaTime
    this.shotDeltaTime += deltaTime;
    // Handle mouse movement
    if (this.scene.input.activePointer.isDown) {
      const playerPos = new Phaser.Math.Vector2(this.x, this.y);
      const pointerPos = new Phaser.Math.Vector2(
        this.scene.input.activePointer.x,
        this.scene.input.activePointer.y
      );

      if (playerPos.distance(pointerPos) > 5) {
        this.scene.physics.moveTo(
          this,
          this.scene.input.activePointer.x,
          this.scene.input.activePointer.y,
          300
        );
      }
    }

    if (this.scene.input.activePointer.isDown || this.scene.keys.space.isDown) {
      // Checks whether shots should be fired
      if (this.shotDeltaTime > this.shotDelay) {
        this.shotDeltaTime = 0;
        this.fireBullet();
      }
    }
    this.checkBulletsPosition();
  }

  fireBullet() {
    if (!this.active) {
      return;
    }
    const bullet = this.scene.physics.add.image(this.x, this.y, "bullet");
    bullet.setVelocity(0, -500);
    this.bullets.add(bullet);
    // If the player collected any power up, spawn extra bullets
    if (this.powerLevel > 1) {
      Array.from({ length: this.powerLevel }).forEach((_, i) => {
        const bulletLeft = this.scene.physics.add.image(
          this.x,
          this.y,
          "bullet"
        );
        const theta = -90 - i * 10;
        this.scene.physics.velocityFromAngle(
          theta,
          500,
          bulletLeft.body.velocity
        );
        this.bullets.add(bulletLeft);

        const bulletRight = this.scene.physics.add.image(
          this.x,
          this.y,
          "bullet"
        );
        const ro = -90 + i * 10;
        this.scene.physics.velocityFromAngle(
          ro,
          500,
          bulletRight.body.velocity
        );
        this.bullets.add(bulletRight);
      });
    }
  }

  checkBulletsPosition() {
    this.bullets.getChildren().forEach(bullet => {
      if (bullet.y < 0) {
        bullet.destroy();
      }
    });
  }

  createAnimation() {
    // Regular fly animation
    this.scene.anims.create({
      key: "fly",
      frames: [
        { key: "player1", frame: 0 },
        { key: "player1", frame: 1 },
        { key: "player1", frame: 2 }
      ],
      frameRate: 30,
      repeat: -1
    });

    this.scene.anims.create({
      key: "ghost",
      frames: [
        { key: "player1", frame: 3 },
        { key: "player1", frame: 0 },
        { key: "player1", frame: 3 },
        { key: "player1", frame: 1 }
      ],
      frameRate: 20,
      repeat: 5
    });
  }

  handleCollision() {
    // Subtract 1 life from player
    this.lives -= 1;
    // Reset power ups count.
    this.powerLevel = 1;
    this.scene.updateGUI();
    // If player has run out lives, trigger game over;
    if (this.lives < 1) {
      // Destroy player
      this.destroy();
      return;
    }

    // Reset the player position
    this.x = initialX;
    this.y = initialY;
    // Animate player ghost time
    this.on(
      `${Phaser.Animations.Events.SPRITE_ANIMATION_KEY_COMPLETE}ghost`,
      () => {
        this.anims.play("fly");
      }
    );
    this.anims.play("ghost");
  }

  addToScore(points = 0) {
    if (this.active) {
      this.score += points;
      this.scene.updateGUI();
    }
  }

  addPowerUp() {
    this.powerLevel += 1;
  }
}
